var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-layout',[_c('w-flex',[_c('w-text-input',{attrs:{"auto-focus":"","color":"primary","counter":_vm.rules.titleLength,"label":_vm.$t('mobileapp.fields.title'),"rules":[
				value => {
					let result = true
					if (!value || value.trim().length <= 0) {
						result = _vm.$t('mobileapp.rules.field.required')
					}
					return result
				},
				value => {
					let result = true
					if (!value || value.trim().length > _vm.rules.titleLength) {
						result = _vm.$tc('mobileapp.rules.field.maxlen', 0, { fieldname: _vm.$t('mobileapp.fields.title').toLowerCase(), max: _vm.rules.titleLength })
					}
					return result
				},
				value => {
					let result = true
					if (!value || (value.length > 0 && value == _vm.application.subtitle)) {
						result = _vm.$tc('mobileapp.rules.field.equal', 0, {
							fieldname1: _vm.$t('mobileapp.fields.title').toLowerCase(),
							fieldname2: _vm.$t('mobileapp.fields.subtitle').toLowerCase(),
						})
					}
					return result
				},
				value => {
					let result = true
					if (!value || (value.length > 0 && value == _vm.application.promotional_text)) {
						result = _vm.$tc('mobileapp.rules.field.equal', 0, {
							fieldname1: _vm.$t('mobileapp.fields.title').toLowerCase(),
							fieldname2: _vm.$t('mobileapp.fields.promotional_text').toLowerCase(),
						})
					}
					return result
				}
			]},on:{"input":function($event){_vm.application = _vm.application}},model:{value:(_vm.application.title),callback:function ($$v) {_vm.$set(_vm.application, "title", $$v)},expression:"application.title"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }